import { axiosInstance } from '../Axios';
import {
	storeData,
	toggleModal,
	toggleModalFetch,
	toggleModalMarkup,
	toggleModalUpdateRail,
	toggleModalUpdateRailPlan,
} from './UiReducer';
import axios from 'axios';
const { createSlice } = require('@reduxjs/toolkit');

const HomeReducer = createSlice({
	name: 'home',
	initialState: {
		success: false,
		error: null,
		loading: false,
		loginInfo: sessionStorage.getItem('loginInfo')
			? JSON.parse(sessionStorage.getItem('loginInfo'))
			: null,
		agent: null,
		product: null,
		addbanner: null,
		deletebanner: null,
		category: null,
		categorydelete: null,
		addcategory: null,
		createproduct: null,
		deleteproduct: null,
		addFund: null,
		debitFund: null,
		trans: null,
		search: null,
		bannerInfo: null,
		pdInfo: null,
		addpdInfo: null,
		partnermar: null,
		popdescdelete: null,
		sector: null,
		gallery: null,
		booking: null,
		downloadTicket: null,
		agenttrans: null,
		manageMarkup: null,
		manageMarkupUpdate: null,
		updateStatus: null,
		updateVerified: null,
		adminUpdate: null,
		tourdest: null,
		tourpackage: null,
		adminPassword: null,
		footerMenu: null,
		updateFooter: null,
		mail: null,
		railid: null,
		railOrder: null,
		token: null,
		railstatus: null,
		railPlan: null,
		plan: null,
		Updateplan: null,
		railledger: null,
	},
	reducers: {
		loginRequest(state, action) {
			state.loading = true;
		},
		loginSuccess(state, action) {
			state.loading = false;
			state.loginInfo = action.payload;
			storeData('loginInfo', action.payload);
			state.error = null;
		},
		loginFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		agentRequest(state, action) {
			state.loading = true;
		},
		agentSuccess(state, action) {
			state.loading = false;
			state.agent = action.payload;
			state.error = null;
		},
		agentFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		addBannerRequest(state, action) {
			state.loading = true;
		},
		addBannerSuccess(state, action) {
			state.loading = false;
			state.addbanner = action.payload;
			state.error = null;
		},
		addBannerFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		bannerdeleteRequest(state, action) {
			state.loading = true;
		},
		bannerdeleteSuccess(state, action) {
			state.loading = false;
			state.deletebanner = action.payload;
			state.error = null;
		},
		bannerdeleteFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		addFundRequest(state, action) {
			state.loading = true;
		},
		addFundSuccess(state, action) {
			state.loading = false;
			state.addFund = action.payload;
			state.error = null;
		},
		addFundFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		debitFundRequest(state, action) {
			state.loading = true;
		},
		debitFundSuccess(state, action) {
			state.loading = false;
			state.debitFund = action.payload;
			state.error = null;
		},
		debitFundFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		transRequest(state, action) {
			state.loading = true;
		},
		transSuccess(state, action) {
			state.loading = false;
			state.trans = action.payload;
			state.error = null;
		},
		transFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		searchRequest(state, action) {
			state.loading = true;
		},
		searchSuccess(state, action) {
			state.loading = false;
			state.search = action.payload;
			state.error = null;
		},
		searchFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		bannerRequest(state, action) {
			state.loading = true;
		},
		bannerSuccess(state, action) {
			state.loading = false;
			state.bannerInfo = action.payload;
			storeData('bannerInfo', action.payload);
			state.error = null;
		},
		bannerFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		pdRequest(state, action) {
			state.loading = true;
		},
		pdSuccess(state, action) {
			state.loading = false;
			state.pdInfo = action.payload;
			storeData('pdInfo', action.payload);
			state.error = null;
		},
		pdFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		addpdBannerRequest(state, action) {
			state.loading = true;
		},
		addpdBannerSuccess(state, action) {
			state.loading = false;
			state.addpdInfo = action.payload;
			storeData('addpdInfo', action.payload);
			state.error = null;
		},
		addpdBannerFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		partnermarRequest(state, action) {
			state.loading = true;
		},
		partnermarSuccess(state, action) {
			state.loading = false;
			state.partnermar = action.payload;
			storeData('partnermar', action.payload);
			state.error = null;
		},
		partnermarFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		popdescdeleteRequest(state, action) {
			state.loading = true;
		},
		popdescdeleteSuccess(state, action) {
			state.loading = false;
			state.popdescdelete = action.payload;
			state.error = null;
		},
		popdescdeleteFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		bookingRequest(state, action) {
			state.loading = true;
		},
		bookingSuccess(state, action) {
			state.loading = false;
			state.booking = action.payload;
			state.error = null;
		},
		bookingFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		downloadRequest(state, action) {
			state.loading = true;
		},
		downloadSuccess(state, action) {
			state.loading = false;
			state.downloadTicket = action.payload;
			state.error = null;
		},
		downloadFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		agenttransRequest(state, action) {
			state.loading = true;
		},
		agenttransSuccess(state, action) {
			state.loading = false;
			state.agenttrans = action.payload;
			state.error = null;
		},
		agenttransFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		manageMarkupRequest(state, action) {
			state.loading = true;
		},
		manageMarkupSuccess(state, action) {
			state.loading = false;
			state.manageMarkup = action.payload;
			state.error = null;
		},
		manageMarkupFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		manageMarkupUpdateRequest(state, action) {
			state.loading = true;
		},
		manageMarkupUpdateSuccess(state, action) {
			state.loading = false;
			state.manageMarkupUpdate = action.payload;
			state.error = null;
		},
		manageMarkupUpdateFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		statusUpdateRequest(state, action) {
			state.loading = true;
		},
		statusUpdateSuccess(state, action) {
			state.loading = false;
			state.updateStatus = action.payload;
			state.error = null;
		},
		statusUpdateFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		verifiedUpdateRequest(state, action) {
			state.loading = true;
		},
		verifiedUpdateSuccess(state, action) {
			state.loading = false;
			state.updateVerified = action.payload;
			state.error = null;
		},
		verifiedUpdateFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		adminUpdateRequest(state, action) {
			state.loading = true;
		},
		adminUpdateSuccess(state, action) {
			state.loading = false;
			state.adminUpdate = action.payload;
			state.error = null;
		},
		adminUpdateFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		tourdestRequest(state, action) {
			state.loading = true;
		},
		tourdestSuccess(state, action) {
			state.loading = false;
			state.tourdest = action.payload;
			state.error = null;
		},
		tourdestFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		tourpackageRequest(state, action) {
			state.loading = true;
		},
		tourpackageSuccess(state, action) {
			state.loading = false;
			state.tourpackage = action.payload;
			state.error = null;
		},
		tourpackageFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		adminPasswordRequest(state, action) {
			state.loading = true;
		},
		adminPasswordSuccess(state, action) {
			state.loading = false;
			state.adminPassword = action.payload;
			state.error = null;
		},
		adminPasswordFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		footerMenuRequest(state, action) {
			state.loading = true;
		},
		footerMenuSuccess(state, action) {
			state.loading = false;
			state.footerMenu = action.payload;
			state.error = null;
		},
		footerMenuFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		updateFooterRequest(state, action) {
			state.loading = true;
		},
		updateFooterSuccess(state, action) {
			state.loading = false;
			state.updateFooter = action.payload;
			state.error = null;
		},
		updateFooterFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		mailRequest(state, action) {
			state.loading = true;
		},
		mailSuccess(state, action) {
			state.loading = false;
			state.mail = action.payload;
			state.error = null;
		},
		mailFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		UpdateRailIdRequest(state, action) {
			state.loading = true;
		},
		UpdateRailIdSuccess(state, action) {
			state.loading = false;
			state.railid = action.payload;
			state.error = null;
		},
		UpdateRailIdFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		railOrderRequest(state, action) {
			state.loading = true;
		},
		railOrderSuccess(state, action) {
			state.loading = false;
			state.railOrder = action.payload;
			state.error = null;
		},
		railOrderFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		tokenRequest(state, action) {
			state.loading = true;
		},
		tokenSuccess(state, action) {
			state.loading = false;
			state.token = action.payload;
			state.error = null;
		},
		tokenFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		railstatusRequest(state, action) {
			state.loading = true;
		},
		railstatusSuccess(state, action) {
			state.loading = false;
			state.railstatus = action.payload;
			state.error = null;
		},
		railstatusFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		UpdateRailagentplanRequest(state, action) {
			state.loading = true;
		},
		UpdateRailagentplanSuccess(state, action) {
			state.loading = false;
			state.railPlan = action.payload;
			state.error = null;
		},
		UpdateRailagentplanFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		railPlanRequest(state, action) {
			state.loading = true;
		},
		railPlanSuccess(state, action) {
			state.loading = false;
			state.plan = action.payload;
			state.error = null;
		},
		railPlanFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		UpdateRailPlanRequest(state, action) {
			state.loading = true;
		},
		UpdateRailPlanSuccess(state, action) {
			state.loading = false;
			state.Updateplan = action.payload;
			state.error = null;
		},
		UpdateRailPlanFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		railledgerRequest(state, action) {
			state.loading = true;
		},
		railledgerSuccess(state, action) {
			state.loading = false;
			state.railledger = action.payload;
			state.error = null;
		},
		railledgerFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
	},
});

const { actions } = HomeReducer;
const config = {
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
};
const config2 = {
	headers: {
		'Content-Type': 'multipart/form-data',
		Accept: 'application/json',
	},
};
const config3 = {
	headers: {
		'Content-Type': 'application/json',
		'Client-Service': 'srk-client',
		'Auth-Key': 'srkapi',
		// 'Api-Key': 'NjO7hqe3PG9musafi==',
		'Api-Key': 'NjExYjdhMzUzNTgxZg==',
	},
};
export const {
	loginRequest,
	loginSuccess,
	loginFail,
	agentRequest,
	agentSuccess,
	agentFail,
	addBannerRequest,
	addBannerSuccess,
	addBannerFail,
	bannerdeleteRequest,
	bannerdeleteSuccess,
	bannerdeleteFail,
	addFundRequest,
	addFundSuccess,
	addFundFail,
	debitFundRequest,
	debitFundSuccess,
	debitFundFail,
	transRequest,
	transSuccess,
	transFail,
	searchRequest,
	searchSuccess,
	searchFail,
	bannerRequest,
	bannerSuccess,
	bannerFail,
	pdRequest,
	pdSuccess,
	pdFail,
	addpdBannerRequest,
	addpdBannerSuccess,
	addpdBannerFail,
	partnermarRequest,
	partnermarSuccess,
	partnermarFail,
	popdescdeleteRequest,
	popdescdeleteSuccess,
	popdescdeleteFail,
	bookingRequest,
	bookingSuccess,
	bookingFail,
	downloadRequest,
	downloadSuccess,
	downloadFail,
	agenttransRequest,
	agenttransSuccess,
	agenttransFail,
	manageMarkupRequest,
	manageMarkupSuccess,
	manageMarkupFail,
	manageMarkupUpdateRequest,
	manageMarkupUpdateSuccess,
	manageMarkupUpdateFail,
	statusUpdateRequest,
	statusUpdateSuccess,
	statusUpdateFail,
	verifiedUpdateRequest,
	verifiedUpdateSuccess,
	verifiedUpdateFail,
	adminUpdateRequest,
	adminUpdateSuccess,
	adminUpdateFail,
	tourdestRequest,
	tourdestSuccess,
	tourdestFail,
	tourpackageRequest,
	tourpackageSuccess,
	tourpackageFail,
	adminPasswordRequest,
	adminPasswordSuccess,
	adminPasswordFail,
	footerMenuRequest,
	footerMenuSuccess,
	footerMenuFail,
	updateFooterRequest,
	updateFooterSuccess,
	updateFooterFail,
	mailRequest,
	mailSuccess,
	mailFail,
	UpdateRailIdRequest,
	UpdateRailIdSuccess,
	UpdateRailIdFail,
	railOrderRequest,
	railOrderSuccess,
	railOrderFail,
	tokenRequest,
	tokenSuccess,
	tokenFail,
	railstatusRequest,
	railstatusSuccess,
	railstatusFail,
	UpdateRailagentplanRequest,
	UpdateRailagentplanSuccess,
	UpdateRailagentplanFail,
	railPlanRequest,
	railPlanSuccess,
	railPlanFail,
	UpdateRailPlanRequest,
	UpdateRailPlanSuccess,
	UpdateRailPlanFail,
	railledgerRequest,
	railledgerSuccess,
	railledgerFail,
} = actions;

export const loginDispatch =
	(bodyData, setLoading, navigate) => async (dispatch) => {
		try {
			dispatch(loginRequest());
			// console.log('body', bodyData);

			const { data } = await axiosInstance.post(
				'/agent/adminlogin',
				bodyData,
				config3
			);
			dispatch(loginSuccess(data));
			if (data.status === 200) {
				navigate('/dashboard/customerlist');
				setLoading(false);
			} else {
				setLoading(false);
				alert('Something went wrong');
			}
		} catch (error) {
			setLoading(false);
			alert('Something went wrong');
			dispatch(
				loginFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const showagentDispatch = () => async (dispatch) => {
	try {
		dispatch(agentRequest());
		const { data } = await axiosInstance.get('/home/adminsAgentist', config3);
		console.log('data', data);
		dispatch(agentSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			agentFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showBookingDispatch = () => async (dispatch) => {
	try {
		dispatch(bookingRequest());
		const { data } = await axiosInstance.get('/home/adminBookingList', config3);
		console.log('data', data);
		dispatch(bookingSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			bookingFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showpartnermarDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(partnermarRequest());
		const { data } = await axiosInstance.post(
			'/partner/showpartnermarkup',
			bodyData,
			config
		);
		dispatch(partnermarSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			partnermarFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showtransDispatch = () => async (dispatch) => {
	try {
		dispatch(transRequest());
		const { data } = await axiosInstance.get('/home/admintran', config3);
		console.log('data', data);
		dispatch(transSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			transFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showagenttransDispatch = () => async (dispatch) => {
	try {
		dispatch(agenttransRequest());
		const { data } = await axiosInstance.get('/home/agenttranrail', config3);
		console.log('data', data);
		dispatch(agenttransSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			agenttransFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showsearchDispatch = () => async (dispatch) => {
	try {
		dispatch(searchRequest());
		const { data } = await axiosInstance.get(
			'/home/adminflightsearchAll',
			config3
		);
		console.log('data', data);
		dispatch(searchSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			searchFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addFundAgentDispatch = (body) => async (dispatch) => {
	try {
		dispatch(toggleModal(false));
		dispatch(addFundRequest());
		const { data } = await axiosInstance.post(
			'/home/addFundRail',
			body,
			config3
		);
		console.log('data', data);
		dispatch(addFundSuccess(data));
		if (data.status === 200) {
			alert('Transaction Successfully');
		}
		dispatch(showagentDispatch());
	} catch (error) {
		console.log('error', error);
		dispatch(
			addFundFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const debitFundAgentDispatch = (body) => async (dispatch) => {
	try {
		dispatch(toggleModalFetch(false));
		dispatch(debitFundRequest());
		const { data } = await axiosInstance.post(
			'/home/fetchFundRail',
			body,
			config3
		);
		console.log('data', data);
		dispatch(debitFundSuccess(data));
		if (data.status === 200) {
			alert('Transaction Successfully');
		} else {
			alert('Transaction Failed');
		}
		dispatch(showagentDispatch());
	} catch (error) {
		console.log('error', error);
		dispatch(
			debitFundFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const createbannerDispatch =
	(bodyData, navigate) => async (dispatch) => {
		try {
			// onUploadProgress: function (progressEvent) {
			// 	var percentCompleted = Math.round(
			// 		(progressEvent.loaded * 100) / progressEvent.total
			// 	);
			// 	console.log(percentCompleted);
			// },

			dispatch(addBannerRequest());
			const { data } = await axios.post(
				'https://s.marinersforex.com/api/home/addbanner',
				bodyData,
				config2,
				prog
			);
			console.log('data', data);

			dispatch(addBannerSuccess(data));
			navigate('/dashboard/bannerlist');
		} catch (error) {
			dispatch(
				addBannerFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const showBannerDispatch = () => async (dispatch) => {
	try {
		dispatch(bannerRequest());
		const { data } = await axios.get(
			'https://s.marinersforex.com/api/home/banner',
			config
		);
		console.log('data', data);
		dispatch(bannerSuccess(data));
	} catch (error) {
		dispatch(
			bannerFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const pdBannerDispatch = () => async (dispatch) => {
	try {
		dispatch(pdRequest());
		const { data } = await axiosInstance.get('/product', config);
		console.log('data', data);
		dispatch(pdSuccess(data));
	} catch (error) {
		dispatch(
			pdFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addbannerDispatch = (bodyData, navigate) => async (dispatch) => {
	try {
		dispatch(addpdBannerRequest());
		const { data } = await axiosInstance.post('/product', bodyData, config2);
		console.log('data', data);
		dispatch(addpdBannerSuccess(data));
		navigate('/dashboard/pslist');
	} catch (error) {
		dispatch(
			addpdBannerFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const deletebannerDispatch = (id) => async (dispatch) => {
	try {
		dispatch(bannerdeleteRequest());
		const { data } = await axios.delete(
			`https://s.marinersforex.com/api/home/bannerdelete/${id}`,
			config
		);
		console.log('data', data);
		if (data) {
			dispatch(showBannerDispatch());
		}
		dispatch(bannerdeleteSuccess(data));
	} catch (error) {
		dispatch(
			bannerdeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const deletepopdescDispatch = (id) => async (dispatch) => {
	try {
		dispatch(popdescdeleteRequest());
		const { data } = await axiosInstance.delete(`/product/${id}`, config);
		console.log('data', data);
		if (data) {
			dispatch(pdBannerDispatch());
		}
		dispatch(popdescdeleteSuccess(data));
	} catch (error) {
		dispatch(
			popdescdeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const updatebannerDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(loginRequest());
		console.log('body', bodyData);

		const { data } = await axiosInstance.post('login', bodyData, config);
		console.log('data', data);
		dispatch(loginSuccess(data));
		// setLoading(false);
	} catch (error) {
		dispatch(
			loginFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const downloadFlightTicket = (orderId) => async (dispatch) => {
	try {
		dispatch(downloadRequest());
		const { data } = await axios.get(
			`https://api.musafirbazar.com/api/user/downloadticket/${orderId}`,
			config
		);
		dispatch(downloadSuccess(data));
	} catch (error) {
		dispatch(
			downloadFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const showManageMarkupDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(manageMarkupRequest());
		const { data } = await axiosInstance.post(
			'/home/managemarkupget',
			bodyData,
			config3
		);
		console.log('data', data);
		dispatch(manageMarkupSuccess(data));
	} catch (error) {
		dispatch(
			manageMarkupFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const ManageMarkupUpdateDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(toggleModalMarkup(false));
		dispatch(manageMarkupUpdateRequest());
		const { data } = await axiosInstance.post(
			'/home/managemarkup',
			bodyData,
			config3
		);
		console.log('data', data);
		dispatch(manageMarkupUpdateSuccess(data));
		if (data.status === 200) {
			alert('Update Successfully');
		}
	} catch (error) {
		dispatch(
			manageMarkupUpdateFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const AgentStatusUpdateDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(statusUpdateRequest());
		const { data } = await axiosInstance.post(
			'/home/updateAgentStatus',
			bodyData,
			config3
		);
		console.log('data', data);
		dispatch(statusUpdateSuccess(data));
		if (data.status === 200) {
			dispatch(showagentDispatch());
		}
	} catch (error) {
		dispatch(
			statusUpdateFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const AgentVerifiedUpdateDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(verifiedUpdateRequest());
		const { data } = await axiosInstance.post(
			'/home/updateAgentVerified',
			bodyData,
			config3
		);
		console.log('data', data);
		dispatch(verifiedUpdateSuccess(data));
		if (data.status === 200) {
			dispatch(showagentDispatch());
		}
	} catch (error) {
		dispatch(
			verifiedUpdateFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const AdminUpdateDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(adminUpdateRequest());
		const { data } = await axiosInstance.post(
			'/home/updateAdminProfile',
			bodyData,
			config3
		);
		console.log('data', data);
		dispatch(adminUpdateSuccess(data));
		if (data.status === 200) {
			alert('Update Successfully');
			// dispatch(showagentDispatch());
		}
	} catch (error) {
		dispatch(
			adminUpdateFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showtourdestDispatch = () => async (dispatch) => {
	try {
		dispatch(tourdestRequest());
		const { data } = await axios.get(
			'https://s.marinersforex.com/api/home/tourdest',
			config
		);
		console.log('data', data);
		dispatch(tourdestSuccess(data));
	} catch (error) {
		dispatch(
			tourdestFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showtourpackageDispatch = () => async (dispatch) => {
	try {
		dispatch(tourpackageRequest());
		const { data } = await axios.get(
			'https://s.marinersforex.com/api/home/tourpackage',
			config
		);
		console.log('data', data);
		dispatch(tourpackageSuccess(data));
	} catch (error) {
		dispatch(
			tourpackageFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const AdminResetPasswordDispatch =
	(bodyData, navigate) => async (dispatch) => {
		try {
			dispatch(adminPasswordRequest());
			const { data } = await axiosInstance.post(
				'/agent/resetpasswordadmin',
				bodyData,
				config3
			);
			console.log('data', data);
			dispatch(adminPasswordSuccess(data));
			if (data.status === 200) {
				alert('Update Successfully');
				navigate('/dashboard/default');
			} else {
				alert(data.result?.message);
			}
		} catch (error) {
			dispatch(
				adminPasswordFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const showFootermenuDispatch = () => async (dispatch) => {
	try {
		dispatch(footerMenuRequest());
		const { data } = await axiosInstance.get('/home/footer_submenu', config3);
		console.log('data', data);
		dispatch(footerMenuSuccess(data));
	} catch (error) {
		dispatch(
			footerMenuFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const UpdateFooterMenuDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(updateFooterRequest());
		const { data } = await axiosInstance.post(
			'/home/updateFooterMenu',
			bodyData,
			config3
		);
		console.log('data', data);
		dispatch(updateFooterSuccess(data));
	} catch (error) {
		dispatch(
			updateFooterFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const UpdateRailIdDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(UpdateRailIdRequest());
		const { data } = await axiosInstance.post(
			'/home/UpdateRailId',
			bodyData,
			config3
		);
		console.log('data', data);
		dispatch(UpdateRailIdSuccess(data));
		dispatch(showagentDispatch());
		dispatch(toggleModalUpdateRail(false));
	} catch (error) {
		dispatch(
			UpdateRailIdFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const UpdateRailagentPlanDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(UpdateRailagentplanRequest());
		const { data } = await axiosInstance.post(
			'/home/UpdateRailAgentPlan',
			bodyData,
			config3
		);
		console.log('data', data);
		dispatch(UpdateRailagentplanSuccess(data));
		dispatch(showagentDispatch());
		dispatch(toggleModalUpdateRailPlan(false));
	} catch (error) {
		dispatch(
			UpdateRailagentplanFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const RailOrderDispatch = () => async (dispatch) => {
	try {
		dispatch(railOrderRequest());
		const { data } = await axiosInstance.get('/home/getRailOrder', config3);
		console.log('data', data);
		dispatch(railOrderSuccess(data));
	} catch (error) {
		dispatch(
			railOrderFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const RailLedgerDispatch = () => async (dispatch) => {
	try {
		dispatch(railledgerRequest());
		const { data } = await axiosInstance.get('/home/getRailledger', config3);
		console.log('data', data);
		dispatch(railledgerSuccess(data));
	} catch (error) {
		dispatch(
			railledgerFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const RailPlanDispatch = () => async (dispatch) => {
	try {
		dispatch(railPlanRequest());
		const { data } = await axiosInstance.get('/home/getRailPlan', config3);
		console.log('data', data);
		dispatch(railPlanSuccess(data));
	} catch (error) {
		dispatch(
			railPlanFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const railgetToken = () => async (dispatch) => {
	try {
		dispatch(tokenRequest());

		const { data } = await axiosInstance.get('home/getRailToken', config3);
		console.log(data);
		dispatch(tokenSuccess(data));
	} catch (error) {
		dispatch(
			tokenFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const railstatus = (bodyData) => async (dispatch) => {
	try {
		dispatch(railstatusRequest());

		const { data } = await axios.post(
			`https://api.musafirbazar.com/api/railstatus`,
			bodyData,
			config
		);
		console.log(data);
		dispatch(railstatusSuccess(data));
		// if(data.Status==='')
		alert(data.Status);
	} catch (error) {
		dispatch(
			railstatusFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const mailFlightTicket = (bodyData) => async (dispatch) => {
	try {
		dispatch(mailRequest());
		const { data } = await axios.post(
			`https://s.marinersforex.com/api/home/mailticket`,
			bodyData,
			config
		);
		dispatch(mailSuccess(data));
		if (data.status === 200) {
			alert('Ticket Sent Successfully');
		}
	} catch (error) {
		dispatch(
			mailFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const UpdateRailPlanDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(UpdateRailPlanRequest());
		const { data } = await axiosInstance.post(
			'/home/updateRailPlan',
			bodyData,
			config3
		);
		console.log('data', data);
		dispatch(UpdateRailPlanSuccess(data));
		if (data.status === 200) {
			alert('Update Successfull !!');
		}
	} catch (error) {
		dispatch(
			UpdateRailPlanFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export default HomeReducer;
