const { createSlice } = require('@reduxjs/toolkit');
export const storeData = async (key, value) => {
	try {
		const jsonValue = JSON.stringify(value);
		sessionStorage.setItem(`${key}`, jsonValue);
	} catch (e) {
		// saving error
	}
};

export const getData = async (key) => {
	try {
		const jsonValue = sessionStorage.getItem(`${key}`);
		return jsonValue != null ? JSON.parse(jsonValue) : null;
	} catch (e) {
		// error reading value
	}
};
const UiReducer = createSlice({
	name: 'ui',
	initialState: {
		tab: 'men',
		modal: false,
		agentId: null,
		railId: null,
		modalFetch: false,
		modalmarkup: false,
		modalRailUpdate: false,
		modalRailUpdatePlan: false,
	},
	reducers: {
		setTab(state, action) {
			state.tab = action.payload;
		},
		toggleModal(state, action) {
			state.modal = action.payload;
		},
		setID(state, action) {
			state.agentId = action.payload;
		},
		toggleModalFetch(state, action) {
			state.modalFetch = action.payload;
		},
		toggleModalMarkup(state, action) {
			state.modalmarkup = action.payload;
		},
		setRailID(state, action) {
			state.railId = action.payload;
		},
		toggleModalUpdateRail(state, action) {
			state.modalRailUpdate = action.payload;
		},
		toggleModalUpdateRailPlan(state, action) {
			state.modalRailUpdatePlan = action.payload;
		},
	},
});

const { actions } = UiReducer;

export const {
	setTab,
	toggleModal,
	setID,
	toggleModalFetch,
	toggleModalMarkup,
	setRailID,
	toggleModalUpdateRail,
	toggleModalUpdateRailPlan,
} = actions;

export default UiReducer;
