import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const GoldPlan = Loadable(lazy(() => import('./GoldPlan')));
const SilverPlan = Loadable(lazy(() => import('./SilverPlan')));
const PlatinumPlan = Loadable(lazy(() => import('./PlatinumPlan')));
const AddCategory = Loadable(lazy(() => import('./AddCategory')));

const PlanRoutes = [
	{
		path: '/dashboard/goldplan',
		element: <GoldPlan />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/silverplan',
		element: <SilverPlan />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/platinumplan',
		element: <PlatinumPlan />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/addcategory',
		element: <AddCategory />,
		auth: authRoles.admin,
	},
];

export default PlanRoutes;
